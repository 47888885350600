window.LIVE_UPDATES_CONNECTED = function () {
  console.log('live_updates_connected');
};

window.LIVE_UPDATES_DISCONNECTED = function () {
  console.log('live_updates_disconnected');
};

window.LIVE_UPDATES_REJECTED = function () {
  console.log('live_updates_rejected');
};

window.LIVE_UPDATES_INSTALLED = function () {
  console.log('live_updates_installed');
};

window.LIVE_UPDATES_UNINSTALLED = function () {
  console.log('live_updates_uninstalled');
};

window.LIVE_UPDATES_RECEIVED = function (data) {
  if (data.resource != 'TestNode') {
    if (window.live_updates_array == null) {
      window.live_updates_array = [];
    }
    window.live_updates_array.push(data);
    console.log('live_updates_received data:');
    console.log(JSON.stringify(data));
  }
};

const cableSetup = function () {
  window.App || (window.App = {});
  window.App.cable = window.ActionCable.createConsumer('/cable');
};

export default cableSetup;
